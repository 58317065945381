import * as bootstrap from '../assets/.npm/node_modules/bootstrap';
import Swiper, {Navigation,Pagination} from "../assets/.npm/node_modules/swiper";
//import fullpage from "../assets/.npm/node_modules/fullpage.js";
//import select2 from "../assets/.npm/node_modules/select2";
//import datepicker from "../assets/.npm/node_modules/bootstrap-datepicker"; 
//import stickyTableHeaders from "../assets/.npm/node_modules/sticky-table-headers";
//import init from "./init";

/* eslint-disable */
__webpack_public_path__ = window.PUBLIC_PATH
/* eslint-enable */

// $.fn.datepicker.dates['vi'] = {
//   days: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
//   daysShort: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
//   daysMin: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
//   months: ["Thg1", "Thg2", "Thg3", "Thg4", "Thg5", "Thg6", "Thg7", "Thg8", "Thg9", "Thg10", "Thg11", "Thg12"],
//   monthsShort: ["Thg1", "Thg2", "Thg3", "Thg4", "Thg5", "Thg6", "Thg7", "Thg8", "Thg9", "Thg10", "Thg11", "Thg12"],
//   today: "Hôm Nay",
//   clear: "Xoá",
//   format: "mm/dd/yyyy",
//   titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
//   weekStart: 0
// };

$( document ).ready(function() {
  // $('select').select2({
  //   minimumResultsForSearch: -1
  // });
  // if ($(window).width() > 767) { 
  //   $('#fullpage').fullpage({
  //       scrollBar: false,
  //       navigation: false,
  //       loopBottom: false,
  //       sectionSelector: 'section'
  //   });
  // }

  if ($(window).width() < 992){
    $(document).on("click",".mega-toggle-animated",function() {
      $('.mega-menu-toggle').addClass('mega-menu-open');
    });
  } else {
    $(document).on("click",".mega-menu-link-parent",function(e) {
      e.preventDefault();
      $(this).closest('.mega-menu-item').addClass('show-sub');
    });
  
    $(document).on("click",".mega-menu-panel__close",function(e) {
      e.preventDefault();
      $(this).closest('.mega-menu-item').removeClass('show-sub');
    });
  }

  $(document).on("click",".block-happy__view a",function(e) {
    e.preventDefault();
    $('.block-happy__detail').removeClass('hide');
    $('.block-happy__view ').addClass('hide');
  });

  $(document).on("click",".block-happy__more a",function(e) {
    e.preventDefault();
    $('.block-happy__detail').addClass('hide');
    $('.block-happy__view ').removeClass('hide');
  });

  var btn = $('.back-top');

  $(window).scroll(function() {
    if ($(window).scrollTop() > 300) {
      btn.addClass('show');
    } else {
      btn.removeClass('show');
    }
  });

  btn.on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({scrollTop:0}, '300');
  });
  

  $(document).click(function (e) {
    const closeMenu = $('.mega-toggle-animated');
    const contentMenu = $('.mega-menu');
    if (!closeMenu.is(e.target) && closeMenu.has(e.target).length === 0 && !contentMenu.is(e.target) && contentMenu.has(e.target).length === 0) {
      $('.mega-menu-toggle').removeClass('mega-menu-open');
    }
  });

  let swiper = new Swiper(".slider", {
      modules: [Navigation,Pagination],
      slidesPerView: 1, 
      centeredSlides: true,
      loop: true,
      navigation: {
          nextEl: ".slider-next",
          prevEl: ".slider-prev",
      },
      pagination: {
        el: ".slider-pagination",
        clickable: true,
      },
      breakpoints: {
        // when window width is >= 480px
        580: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        // when window width is >= 640px
        992: {
          slidesPerView: 5,
          spaceBetween: 155
        }
      }
  });

  let swiperProgram = new Swiper(".slider-program", {
    modules: [Navigation,Pagination],
    slidesPerView: 1, 
    spaceBetween: 0,
    loop: true,
    navigation: {
        nextEl: ".program-next",
        prevEl: ".program-prev",
    },
    pagination: {
      el: ".program-pagination",
      clickable: true,
    },
    breakpoints: {
      // when window width is >= 480px
      580: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      // when window width is >= 640px
      992: {
        slidesPerView: 3,
        spaceBetween: 30
      }
    }
  });

  let swiperProgramD = new Swiper(".slider-program-detail", {
    modules: [Navigation,Pagination],
    slidesPerView: 1, 
    spaceBetween: 0,
    loop: true,
    navigation: {
        nextEl: ".program-next",
        prevEl: ".program-prev",
    },
    pagination: {
      el: ".program-pagination",
      clickable: true,
    },
  });

  let swiperOther = new Swiper(".slider-other", {
    modules: [Navigation,Pagination],
    slidesPerView: 1, 
    spaceBetween: 0,
    loop: true,
    navigation: {
        nextEl: ".other-next",
        prevEl: ".other-prev",
    },
    pagination: {
      el: ".other-pagination",
      clickable: true,
    },
    breakpoints: {
      // when window width is >= 480px
      580: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      // when window width is >= 640px
      992: {
        slidesPerView: 3,
        spaceBetween: 30
      }
    }
});
  
});
  
